<template>
  <section>
    <!-- <b-row>
      <b-col lg="12" md="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title> 总览介绍。。。 </b-card-title>
          </b-card-header>
        </b-card>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col lg="8" md="12">
        <b-card no-body class="briefIntroduction">
          <b-card-header class="introducer">
            <b-card-title
              >经过8年漫长的等待，2021年10月19日美国首支比特币期货ETF正式在纳斯达克上线。这对于加密货币市场来说是一个里程碑的事件，它从本质打通了加密货币市场与美国传统市场的资金桥梁。</b-card-title
            >
          </b-card-header>
          <b-card-body>
            <div class="introducerKind">
              <table border="1" class="Kind">
                <tr>
                  <th style="width: 250px">名称</th>
                  <th>代码</th>
                  <th>所属公司</th>
                  <th>类型</th>
                  <th>上线日期</th>
                </tr>
                <tr>
                  <td>ProShares Bitcoin Strategy ETF</td>
                  <td>BITO</td>
                  <td>Proshare</td>
                  <td>期货</td>
                  <td>2021年10月18日</td>
                </tr>
                <tr>
                  <td>Valkyrie Bitcoin Strategy ETF</td>
                  <td>BTF</td>
                  <td>Valkyrie</td>
                  <td>期货</td>
                  <td>2021年10月21日</td>
                </tr>
                <tr>
                  <td>VanEck Bitcoin Strategy ETF</td>
                  <td>XBTF</td>
                  <td>VanEck</td>
                  <td>期货</td>
                  <td>2021年11月15日</td>
                </tr>
                <tr>
                  <td>Purpose Bitpcin ETF（Canada）</td>
                  <td>BTCC</td>
                  <td>Purpose</td>
                  <td>期货</td>
                  <td>2021年2月27日</td>
                </tr>
              </table>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="4" md="12">
        <b-card no-body class="prosharesPrice">
          <b-card-body>
            <customized-pie ref="chart1" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4" md="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>所有ETF规模(USD) </b-card-title>
          </b-card-header>
          <b-card-body>
            <inventory ref="AllEtfScale" />
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="4" md="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>所有ETF持仓(USD) </b-card-title>
          </b-card-header>
          <b-card-body>
            <inventory ref="AllEtfPosition" />
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="4" md="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>ETF占总合约持仓比例 </b-card-title>
          </b-card-header>
          <b-card-body>
            <inventory ref="EtfTotalpercentage" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- <b-row>
      <b-col>
        <b-card no-body class="prosharesPrice">
          <b-card-header> </b-card-header>
          <b-card-body>
            <customized-pie ref="chart1" />
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="6">
        <b-card no-body class="prosharesPrice">
          <b-card-header> </b-card-header>
          <b-card-body>
            <customized-pie ref="chart1" />
          </b-card-body>
        </b-card>
         <analytics-support-tracker :data="data.supportTracker" />
      </b-col>
    </b-row> -->
  </section>
</template>
<script>
import {
  getAllBrowseScaleData,
  getAllBrowsePositionData,
  getAllBrowseRateData,
} from "@/api/BtcEtf.js";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import CustomizedPie from "@/views/charts-and-maps/charts/echart/option-echart/CustomizedPie.vue";
import Inventory from "@/views/charts-and-maps/charts/echart/option-echart/Inventory.vue";
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    Inventory,
    CustomizedPie,
  },
  data() {
    return {
      tempData: [], //存放所有ETF规模数据
      ScaleTimeData: [], //所有规模时间
      YCharScaleData: [], //所有y轴规模数据

      AllData: [], //存放所有ETF持仓数据
      PositionTimeData: [], //所有持仓时间
      YCharPositionData: [], //所有y轴持仓数据

      TotalRateData: [], //存放ETF持仓占总市比例数据
      RteTimeData: [], //所有ETF持仓占总市比例时间

      paiData: [],
      YCharRateData: [], //所有ETF持仓占总市比例y轴数据

      PositionPaiData: [],
    };
  },
  created() {
    this.getScaleList();
    this.getPositionList();
    this.getRateList();
  },
  mounted() {},
  methods: {
    //请求所有规模接口
    getScaleList() {
      getAllBrowseScaleData()
        .then((res) => {
          let tempData = [...res.data.data];

          let set = new Set();
          for (let i = 0; i < tempData.length; i++) {
            let day =
              new Date(tempData[i].datetime).getDate() < 10
                ? "0" + new Date(tempData[i].datetime).getDate()
                : new Date(tempData[i].datetime).getDate();
            set.add(new Date(tempData[i].datetime).getMonth() + 1 + "-" + day);
          }
          this.ScaleTimeData = Array.from(set);

          this.YCharScaleData = tempData.map((v) =>
            (v.value / 100000000).toFixed(2)
          );

          this.fundScaleData(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //请求所有持仓接口
    getPositionList() {
      getAllBrowsePositionData()
        .then((res) => {
          let AllData = [...res.data.data];
          // console.log(1111, ...AllData);

          let set = new Set();
          for (let i = 0; i < AllData.length; i++) {
            let day =
              new Date(AllData[i].datetime).getDate() < 10
                ? "0" + new Date(AllData[i].datetime).getDate()
                : new Date(AllData[i].datetime).getDate();
            set.add(new Date(AllData[i].datetime).getMonth() + 1 + "-" + day);
          }
          this.PositionTimeData = Array.from(set);
          // console.log("-->", this.PositionTimeData);

          this.YCharPositionData = AllData.map((v) =>
            (v.value / 100000000).toFixed(2)
          );
          //  console.log("123",this.YCharPositionData)

          this.fundPositionData(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //请求持仓占总市比例信息
    getRateList() {
      getAllBrowseRateData()
        .then((res) => {
          let TotalRateData = [...res.data.data];
          // console.log(1111, ...TotalRateData);

          let set = new Set();
          for (let i = 0; i < TotalRateData.length; i++) {
            let day =
              new Date(TotalRateData[i].datetime).getDate() < 10
                ? "0" + new Date(TotalRateData[i].datetime).getDate()
                : new Date(TotalRateData[i].datetime).getDate();
            set.add(
              new Date(TotalRateData[i].datetime).getMonth() + 1 + "-" + day
            );
          }
          this.RteTimeData = Array.from(set);
          // console.log("-->", this.RteTimeData);

          this.YCharRateData = TotalRateData.map((v) =>
            (v.value * 100).toFixed(2)
          );

          let paiData = TotalRateData.reverse();
          this.PositionPaiData = (paiData[0].value * 100).toFixed(2);
          // console.log("--", this.PositionPaiData);

          this.fundPositionRate(1);
          this.dealEchartData(1);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //获取基金规模图表上数据
    fundScaleData(type) {
      if (type === 1) {
        this.$refs.AllEtfScale.chart.setOption({
          legend: {
            textStyle: {
              color: "#ffff",
            },
          },
          xAxis: {
            data: this.ScaleTimeData.reverse(),
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: {
            type: "value",
            nameTextStyle: {
              width: 100,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
            axisLabel: {
              margin: 2, //图表显示y轴数据调试修改距离设置外边距
              show: true,
              interval: "auto",
              formatter: "{value} 亿",
              textStyle: {
                color: "#FFF",
              },
            },
            grid: {
              left: 90,
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          series: [
            {
              name: "资产净值",
              data: this.YCharScaleData.reverse(),
              type: "bar",
              itemStyle: {
                color: "#EFC394",
              },
            },
          ],
        });
      }
    },
    //获取持仓规模图表上数据
    fundPositionData(type) {
      if (type === 1) {
        this.$refs.AllEtfPosition.chart.setOption({
          legend: {
            textStyle: {
              color: "#ffff",
            },
          },
          xAxis: {
            data: this.PositionTimeData.reverse(),
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: {
            type: "value",
            nameTextStyle: {
              width: 100,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} 亿",
              textStyle: {
                color: "#FFF",
              },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          series: [
            {
              name: "持仓",
              data: this.YCharPositionData.reverse(),
              type: "bar",
              itemStyle: {
                color: "#EFC394",
              },
            },
          ],
        });
      }
    },
    //获取持仓持仓占总市比例信息图表上数据
    fundPositionRate(type) {
      if (type === 1) {
        this.$refs.EtfTotalpercentage.chart.setOption({
          legend: {
            textStyle: {
              color: "#ffff",
            },
          },
          xAxis: {
            data: this.RteTimeData,
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          yAxis: {
            type: "value",
            nameTextStyle: {
              width: 100,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} %",
              margin: 2,
              textStyle: {
                color: "#FFF",
              },
            },
          },
          grid: {
            left: 90,
          },
          // dataZoom: [
          //   {
          //     type: "inside",
          //     start: 0,
          //     end: 10,
          //   },
          //   {
          //     start: 0,
          //     end: 10,
          //   },
          // ],
          series: [
            {
              name: "持仓",
              data: this.YCharRateData,
              type: "bar",
              itemStyle: {
                color: "#EFC394",
              },
            },
          ],
        });
      }
    },
    //获取圆饼data值
    dealEchartData(type) {
      if (type === 1) {
        this.$refs.chart1.chart.setOption({
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: "bottom",
            textStyle: {
              color: "#FFF",
            },
          },
          visualMap: {
            show: false,
            min: 80,
            max: 600,
            inRange: {
              colorLightness: [0, 1],
            },
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: "55%",
              center: ["50%", "50%"],
              data: [
                {
                  value: this.PositionPaiData,
                  name: "ETF持仓占全市场期货总市值比例",
                  itemStyle: { color: "#EFC394" },
                },
                {
                  value: 100 - this.PositionPaiData,
                  name: "",
                  itemStyle: { color: "#161D31" },
                },
              ].sort(function (a, b) {
                return a.value - b.value;
              }),
              // roseType: "radius",
              label: {
                color: "#FFFF",
              },
              labelLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.3)",
                },
                smooth: 0.2,
                length: 10,
                length2: 20,
              },
              itemStyle: {
                color: "#c23531",
                shadowBlur: 200,
                shadowColor: "rgba(0, 0, 0, 1.0)",
              },
              animationType: "scale",
              animationEasing: "elasticOut",
              animationDelay: function (idx) {
                return Math.random() * 200;
              },
            },
          ],
        });
      }
    },

    // getFundList(){
    //   getAllBrowseData()
    //   .then((res) =>{

    //   })
    //   .catch((err) =>{
    //     console.log(err);
    //   });
    // },
  },
};
</script>
<style lang="scss">
.briefIntroduction {
  .introducer {
    .card-title {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
    }
  }
  .card-body {
    .introducerKind {
      color: #ffff;
      .Kind {
        width: 100%;
        border: 1px solid rgba(255, 255, 255, 0.5);
        tr {
          text-align: center;
          height: 48px;
          th {
            width: 200px;
          }
        }
      }
    }
  }
}
</style>